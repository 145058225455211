import { toFixedNumber } from '~/utils'

const currencies = [
  {
    code: 'GBP',
    symbol: '£',
    taxCalculation: 1.2,
  },
]

export const getCurrencySymbol = (code: string) => {
  const result = currencies.find(e => e.code == code)

  return result?.symbol ?? ''
}

export const getPriceExclTax = (code: string, price: string | number) => {
  const result = currencies.find(e => e.code == code)

  return result?.taxCalculation
    ? toFixedNumber(Number(price) / result.taxCalculation, 2)
    : toFixedNumber(Number(price), 2)
}

export const getPriceInclTax = (code: string, price: string | number) => {
  const result = currencies.find(e => e.code == code)

  return result?.taxCalculation
    ? toFixedNumber(Number(price) * result.taxCalculation, 2)
    : toFixedNumber(Number(price), 2)
}

export const formatPrice = (price: string | number) => {
  return Number(price).toFixed(2)
}

export const removeDecimals = (e: number) => {
  return e.toFixed(0)
}
